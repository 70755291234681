import React from 'react'
import { BenefitsContainer } from './style'
import Grid from '@material-ui/core/Grid'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import ImgUtil from '../../../utils/img.utils'
import options from '../../../utils/richText.utils'
import GlobalContainer from '../globalContainer'
import Typography from '../typography'
import Path from '../../../../static/steam-img/path.png'
import formatInlineCode from '../../../utils/shortTextFormatting'
import WrapperImage from '../wrapperImage'

const Benefits = (props: any) => {
  if (props.data) {
    const heading = props.data.headline ? props.data.headline : ''
    const headlineAlignment = props.data.headlineAlignment
      ? props.data.headlineAlignment
      : ''

    const mainImageSrc =
      props.data.image &&
      props.data.image.image &&
      props.data.image.image.file &&
      props.data.image.image.file.url
        ? props.data.image.image.file.url
        : ''

    const mainImageAlt =
      props.data.image &&
      props.data.image.image &&
      props.data.image.image.altText
        ? props.data.image.image.altText
        : ''

    // iconText
    let theme: string = 'Light',
      topPadding: string = 'Large',
      bottomPadding: string = 'Large',
      layout: string = 'Text to Right'

    const icons = props.data.items

    const property = {
      xs: 12,
      sm: 6,
      md: 6,
    }

    return (
      <BenefitsContainer
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        background={theme}
        layout={layout}
        data-cy="Benefits"
      >
        <GlobalContainer className="Benefits__Inner">
          <div className="Benefits__Image-Graphic-Outer">
            <div className="Benefits__Image-Container">
              {mainImageSrc && (
                <WrapperImage
                  className="Benefits__Image"
                  loading="lazy"
                  alt={mainImageAlt}
                  src={ImgUtil.getResizedImageUrl(mainImageSrc, 'width=282')}
                  width={282}
                  height={282}
                  threshold={650}
                />
              )}
            </div>
            <Typography className="Benefits__Image-Typography" type="header2">
              {formatInlineCode(heading)}
            </Typography>

            <WrapperImage
              className="Benefits__Image-Path"
              src={Path}
              alt="Half moon icon"
              threshold={650}
            />
          </div>

          <Grid
            container
            sm={8}
            className="Benefits__Inner-Container Benefits__Inner-Container-Icons"
            spacing={3}
          >
            {icons &&
              icons.map((item: any, index: number) => {
                let icon = ''
                if (
                  item.colorIcon &&
                  item.colorIcon.file &&
                  item.colorIcon.file.url
                ) {
                  icon = item.colorIcon.file.url
                }
                return (
                  <Grid
                    item
                    {...property}
                    className="Benefits__Item-Container"
                    key={index}
                  >
                    {icon && (
                      <WrapperImage
                        src={ImgUtil.getResizedImageUrl(icon, `width=64`)}
                        alt="icon"
                        width={64}
                        height={64}
                        threshold={650}
                      />
                    )}
                    <div className="Benefits__Text-Container">
                      {item.title && (
                        <Typography
                          className="Benefits__Benefit-Header"
                          type="header5"
                        >
                          {item.title}
                        </Typography>
                      )}
                      {item.body && (
                        <Typography
                          className="Benefits__Benefit-Body"
                          type="body"
                        >
                          {renderRichText(item.body, options)}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                )
              })}
          </Grid>
        </GlobalContainer>
      </BenefitsContainer>
    )
  }
  return null
}

export default Benefits
