// Libraries
import React from 'react'
import Layout from '../../layouts/layout'

// Constants
import { ModuleNames } from '../../../constants/componentNames.constants'
import { LocaleCodes } from '../../../constants/localization.constants'

// Components
import Hero from '../../global/hero'
import VideoSpotlight from '../../global/videoSpotlight'
import FeaturedResource from '../../global/featuredResource'
import Divider from '../../global/divider'
import ResourceRow from '../../global/resourceRow'
import ContentColumns from '../../global/contentColumns'
import ContentBlock from '../../global/contentBlock'
import MainCTA from '../../global/mainCta'
import Grid from '../../global/grid'
import SEOTags from '../../global/seoTags'
import MediaText from '../../global/mediaText'
import Quote from '../../global/quote'
import Icons from '../../global/icons'
import KeyStats from '../../global/keyStats'
import Slider from '../../global/slider'
import Table from '../../global/table'
import Benefits from '../../global/benefits'
import ResourceTabs from '../../global/resourceTabs'
import PlatformDiagram from '../../global/platformDiagram'
import CollapsibleQuadrant from '../../global/collapsibleQuadrant'

const HomePage = ({ pageContext, location }: any) => {
  let dynamicPageContent: any
  let hero: any
  let spotlight: any
  let ctaSection: any
  if (
    pageContext &&
    pageContext.homepageData &&
    pageContext.homepageData.hero
  ) {
    const pageTitle =
      pageContext.homepageData && pageContext.homepageData.name
        ? pageContext.homepageData.name
        : ''
    hero = (
      <Hero
        data={pageContext.homepageData.hero}
        location={location}
        pageTitle={pageTitle}
        showBreadcrumbs={false}
        isHomePage={true}
        pageType="home"
      />
    )
  }

  if (
    pageContext &&
    pageContext.homepageData &&
    pageContext.homepageData.spotlight
  ) {
    spotlight = (
      <ResourceRow
        isHomePage={true}
        data={pageContext.homepageData.spotlight}
      ></ResourceRow>
    )
  }

  if (
    pageContext &&
    pageContext.homepageData &&
    pageContext.homepageData.bottomCta
  ) {
    ctaSection = <MainCTA data={pageContext.homepageData.bottomCta}></MainCTA>
  }

  if (
    pageContext &&
    pageContext.homepageData &&
    pageContext.homepageData.flexSection
  ) {
    const homepageContent = pageContext.homepageData.flexSection
    dynamicPageContent = homepageContent.map((module: any, i: Number) => {
      switch (module.__typename) {
        case ModuleNames.DIVIDER:
          return <Divider data={module} key={`homepageModule--${i}`}></Divider>

        case ModuleNames.MEDIA_TEXT:
          return (
            <MediaText data={module} key={`homepageModule--${i}`}></MediaText>
          )

        case ModuleNames.GLOBAL_KEY_STATS:
          return (
            <KeyStats
              data={module}
              isHomePage={true}
              key={`homepageModule--${i}`}
            ></KeyStats>
          )
        case ModuleNames.ANCHOR_LINK:
          const identifier = module?.identifier ? module.identifier : ''
          return <span id={identifier}></span>

        case ModuleNames.VIDEO_SPOTLIGHT:
          return (
            <VideoSpotlight
              data={module}
              key={`homepageModule--${i}`}
            ></VideoSpotlight>
          )

        case ModuleNames.GLOBAL_CONTENT_COLUMNS:
          return (
            <ContentColumns
              data={module}
              key={`homepageModule--${i}`}
            ></ContentColumns>
          )

        case ModuleNames.GLOBAL_CONTENT_BLOCK:
          return (
            <ContentBlock
              data={module}
              key={`homepageModule--${i}`}
            ></ContentBlock>
          )

        case ModuleNames.RESOURCE_ROW:
          return (
            <ResourceRow
              data={module}
              key={`homepageModule--${i}`}
            ></ResourceRow>
          )

        case ModuleNames.ICONS:
          return <Icons data={module} key={`homepageModule--${i}`}></Icons>

        case ModuleNames.GRID:
          return (
            <Grid
              data={module}
              isHomePage={true}
              key={`homepageModule--${i}`}
            ></Grid>
          )

        case ModuleNames.QUOTE:
          return <Quote data={module} key={`homepageModule--${i}`}></Quote>

        case ModuleNames.FEATURED_RESOURCE:
          return (
            <FeaturedResource
              data={module}
              key={`homepageModule--${i}`}
            ></FeaturedResource>
          )

        case ModuleNames.SLIDER:
          return <Slider data={module} key={`homepageModule--${i}`}></Slider>

        case ModuleNames.TABLE:
          return <Table data={module} key={`homepageModule--${i}`}></Table>

        case ModuleNames.BENEFITS:
          return (
            <Benefits data={module} key={`homepageModule--${i}`}></Benefits>
          )

        case ModuleNames.RESOURCE_TABS:
          return (
            <ResourceTabs
              data={module}
              key={`homepageModule--${i}`}
            ></ResourceTabs>
          )
        case ModuleNames.PLATFORM_DIAGRAM:
          return <PlatformDiagram data={module} key={`homepageModule--${i}`} />

        case ModuleNames.COLLAPSIBLE_QUADRANT:
          return (
            <CollapsibleQuadrant data={module} key={`homepageModule--${i}`} />
          )
      }
    })
  }

  let defaultLocale
  if (pageContext && pageContext.pageLocale) {
    defaultLocale = pageContext.pageLocale
  } else {
    defaultLocale = LocaleCodes.ENGLISH.code
  }

  const defaultSlug =
    pageContext && pageContext.homepageData && pageContext.homepageData.slug
      ? pageContext.homepageData.slug
      : '/'
  const seoData =
    pageContext &&
    pageContext.homepageData &&
    pageContext.homepageData.seoMetadata
      ? pageContext.homepageData.seoMetadata
      : null

  const pageInner = (
    <div data-cy="Homepage">
      <SEOTags
        seoMetadata={seoData}
        slug={undefined}
        locale={defaultLocale}
        siteSection="Homepage"
      />
      {hero}
      {spotlight}
      {dynamicPageContent}
      {ctaSection}
    </div>
  )

  return (
    <Layout.Template
      lang={defaultLocale}
      pageType="Homepage"
      slug={defaultSlug}
    >
      {pageInner}
    </Layout.Template>
  )
}

export default HomePage
